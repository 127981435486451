import { ReactNode } from "react";
import { CategoryData } from "@/modules/Astro/Enum";

export type GeolocationCoordinates = {
  latitude: number;
  longitude: number;
  accuracy: number;
};
export type TabItem = {
  key: string;
  label: ReactNode;
  children: ReactNode;
  disabled?: boolean;
};
export interface FormResponse {
  [key: string]: string | number | boolean | null | undefined | Date;
}
export type GeolocationPosition = {
  coords: GeolocationCoordinates;
};

export enum SIDE_LINKS_EVENTS {
  LIVE_ROOMS = "Live Rooms",
  COMMUNITY = "Community",
  AUDIO_CALL = "Call",
  FEED = "Feed",
  WALLET = "Wallet",
}
export enum CATEGORY_FEED_TYPE {
  audio = "AR",
  live = "LR",
  PRIVATE_CALL = "PC",
}

export enum REQUEST_METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export enum DOWNLOAD_POPUP_SOURCE {
  JOIN_LIVE = "JOIN_LIVE",
  SEND_GIFTS = "SEND_GIFTS",
  FOLLOW = "FOLLOW",
  CHAT_CLICKED = "CHAT_CLICKED",
  PROFILE_ICON = "PROFILE_ICON",
}

export enum DOWNLOAD_APP_SOURCE {
  AUDIO_PAGE = "Audio Homepage",
  LIVE_PAGE = "Live Homepage",
  JOIN_LIVE = "Join Live",
  SEND_GIFTS = "Send Gifts",
  FOLLOW = "Follow",
  CHAT_CLICKED = "Chat",
  PROFILE_ICON = "Profile Icon",
}
export enum COUNTRY_CODES {
  AFGHANISTAN = "af",
  ALBANIA = "al",
  ALGERIA = "dz",
  ANDORRA = "ad",
  ANGOLA = "ao",
  ANGUILLA = "ai",
  ANTIGUA_AND_BARBUDA = "ag",
  ARGENTINA = "ar",
  ARMENIA = "am",
  ARUBA = "aw",
  AUSTRALIA = "au",
  AUSTRIA = "at",
  AZERBAIJAN = "az",
  BAHAMAS = "bs",
  BAHRAIN = "bh",
  BANGLADESH = "bd",
  BARBADOS = "bb",
  BELARUS = "by",
  BELGIUM = "be",
  BELIZE = "bz",
  BENIN = "bj",
  BERMUDA = "bm",
  BHUTAN = "bt",
  BOSNIA_AND_HERZEGOVINA = "ba",
  BOTSWANA = "bw",
  BOUVET_ISLAND = "bv",
  BRAZIL = "br",
  BRITISH_INDIAN_OCEAN_TERRITORY = "io",
  BRUNEI = "bn",
  BULGARIA = "bg",
  BURKINA_FASO = "bf",
  BURUNDI = "bi",
  CAMBODIA = "kh",
  CAMEROON = "cm",
  CANADA = "ca",
  CAPE_VERDE = "cv",
  CAYMAN_ISLANDS = "ky",
  CENTRAL_AFRICAN_REPUBLIC = "cf",
  CHAD = "td",
  CHILE = "cl",
  CHINA = "cn",
  CHRISTMAS_ISLAND = "cx",
  COCOS_ISLANDS = "cc",
  COLOMBIA = "co",
  COMOROS = "km",
  CONGO = "cg",
  COOK_ISLANDS = "ck",
  COSTA_RICA = "cr",
  CROATIA = "hr",
  CUBA = "cu",
  CYPRUS = "cy",
  CZECH_REPUBLIC = "cz",
  DENMARK = "dk",
  DJIBOUTI = "dj",
  DOMINICA = "dm",
  DOMINICAN_REPUBLIC = "do",
  ECUADOR = "ec",
  EGYPT = "eg",
  EL_SALVADOR = "sv",
  EQUATORIAL_GUINEA = "gq",
  ERITREA = "er",
  ESTONIA = "ee",
  ETHIOPIA = "et",
  FALKLAND_ISLANDS = "fk",
  FAROE_ISLANDS = "fo",
  FIJI = "fj",
  FINLAND = "fi",
  FRANCE = "fr",
  FRENCH_POLYNESIA = "pf",
  GABON = "ga",
  GAMBIA = "gm",
  GEORGIA = "ge",
  GERMANY = "de",
  GHANA = "gh",
  GIBRALTAR = "gi",
  GREECE = "gr",
  GREENLAND = "gl",
  GRENADA = "gd",
  GUADELOUPE = "gp",
  GUAM = "gu",
  GUATEMALA = "gt",
  GUERNSEY = "gg",
  GUINEA = "gn",
  "GUINEA-BISSAU" = "gw",
  GUYANA = "gy",
  HAITI = "ht",
  HEARD_ISLAND_AND_MCDONALD_ISLANDS = "hm",
  "VATICAN_CITY_STATE_(HOLY_SEE)" = "va",
  HONDURAS = "hn",
  HONG_KONG = "hk",
  HUNGARY = "hu",
  ICELAND = "is",
  INDIA = "in",
  INDONESIA = "id",
  IRAN = "ir",
  IRAQ = "iq",
  IRELAND = "ie",
  ISLE_OF_MAN = "im",
  ISRAEL = "il",
  ITALY = "it",
  JAMAICA = "jm",
  JAPAN = "jp",
  JERSEY = "je",
  JORDAN = "jo",
  KAZAKHSTAN = "kz",
  KENYA = "ke",
  KIRIBATI = "ki",
  KUWAIT = "kw",
  KYRGYZSTAN = "kg",
  LAOS = "la",
  LATVIA = "lv",
  LEBANON = "lb",
  LESOTHO = "ls",
  LIBERIA = "lr",
  LIECHTENSTEIN = "li",
  LITHUANIA = "lt",
  LUXEMBOURG = "lu",
  MACAU = "mo",
  MADAGASCAR = "mg",
  MALAWI = "mw",
  MALAYSIA = "my",
  MALDIVES = "mv",
  MALI = "ml",
  MALTA = "mt",
  MARSHALL_ISLANDS = "mh",
  MARTINIQUE = "mq",
  MAURITANIA = "mr",
  MAURITIUS = "mu",
  MAYOTTE = "yt",
  MEXICO = "mx",
  MONACO = "mc",
  MONGOLIA = "mn",
  MONTENEGRO = "me",
  MONTSERRAT = "ms",
  MOROCCO = "ma",
  MOZAMBIQUE = "mz",
  MYANMAR = "mm",
  NAMIBIA = "na",
  NAURU = "nr",
  NEPAL = "np",
  NETHERLANDS = "nl",
  NEW_CALEDONIA = "nc",
  NEW_ZEALAND = "nz",
  NICARAGUA = "ni",
  NIGER = "ne",
  NIGERIA = "ng",
  NIUE = "nu",
  NORFOLK_ISLAND = "nf",
  NORTHERN_MARIANA_ISLANDS = "mp",
  NORWAY = "no",
  OMAN = "om",
  PAKISTAN = "pk",
  PALAU = "pw",
  PANAMA = "pa",
  PAPUA_NEW_GUINEA = "pg",
  PARAGUAY = "py",
  PERU = "pe",
  PHILIPPINES = "ph",
  PITCAIRN = "pn",
  POLAND = "pl",
  PORTUGAL = "pt",
  PUERTO_RICO = "pr",
  QATAR = "qa",
  RÉUNION = "re",
  ROMANIA = "ro",
  RWANDA = "rw",
  SAINT_KITTS_AND_NEVIS = "kn",
  SAINT_LUCIA = "lc",
  SAINT_PIERRE_AND_MIQUELON = "pm",
  SAINT_VINCENT_AND_THE_GRENADINES = "vc",
  SAMOA = "ws",
  SAN_MARINO = "sm",
  SAO_TOME_AND_PRINCIPE = "st",
  SAUDI_ARABIA = "sa",
  SENEGAL = "sn",
  SERBIA = "rs",
  SEYCHELLES = "sc",
  SIERRA_LEONE = "sl",
  SINGAPORE = "sg",
  SLOVAKIA = "sk",
  SLOVENIA = "si",
  SOLOMON_ISLANDS = "sb",
  SOMALIA = "so",
  SOUTH_AFRICA = "za",
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = "gs",
  SPAIN = "es",
  SRI_LANKA = "lk",
  SUDAN = "sd",
  SURINAME = "sr",
  SWAZILAND = "sz",
  SWEDEN = "se",
  SWITZERLAND = "ch",
  SYRIA = "sy",
  TAIWAN = "tw",
  TAJIKISTAN = "tj",
  THAILAND = "th",
  TIMOR_LESTE = "tl",
  TOGO = "tg",
  TOKELAU = "tk",
  TONGA = "to",
  TRINIDAD_AND_TOBAGO = "tt",
  TUNISIA = "tn",
  TURKEY = "tr",
  TURKMENISTAN = "tm",
  TURKS_AND_CAICOS_ISLANDS = "tc",
  TUVALU = "tv",
  UGANDA = "ug",
  UKRAINE = "ua",
  UAE = "ae",
  UNITED_KINGDOM = "gb",
  USA = "us",
  UNITED_STATES_MINOR_OUTLYING_ISLANDS = "um",
  URUGUAY = "uy",
  UZBEKISTAN = "uz",
  VANUATU = "vu",
  VIETNAM = "vn",
  WALLIS_AND_FUTUNA = "wf",
  YEMEN = "ye",
  ZAMBIA = "zm",
  ZIMBABWE = "zw",
}

export interface AuthReqParams {
  token?: string;
  contextId?: string;
}

export interface RoomFeedResponse {
  data: RoomResponse[];
  user?: UserData | null;
}

export interface AuthData {
  idToken: string;
  isAuthenticated: boolean;
  udt: string;
  user: UserData;
}

export interface UserData {
  _id: string;
  name: string;
  username: string;
  isAdult: boolean;
  profilePic: string;
  status: string;
  isRegistered: boolean;
  token: string;
  isAuthenticated: boolean;
}

export interface LiveRoomData {
  id: number;
  name: string;
  privacy: string;
  userId: string;
  shareableUrl: string;
  roomIcon: string;
  experience: string;
  playUrl: string;
  membersCount: number;
  isCreator: boolean;
  languages: string[];
  creatorSubCategories: string[];
}

export interface UserProfileData {
  userId: string;
  rate: number;
  rateInInr: number;
  userName: string;
  name: string;
  country: string;
  rating: string;
  status: string;
  level: number;
  photo: string;
  experience: number;
}

export interface RoomResponse {
  liveRoom: LiveRoomData;
  userProfile: UserProfileData;
}

export interface RoomsPageData {
  data: RoomResponse[];
  user?: UserData;
  error?: ErrorType["code"];
  categories: CategoryData[];
  nonAuthToken?: string;
}

export enum ROOM_TYPE {
  AUDIO = "audio",
  LIVE = "live",
  CALL = "call",
  CHAT = "chat",
}

export interface ErrorType {
  code: number;
  message: string;
}

export enum LIVE_ROOM_ROLES {
  OWNER = "owner",
}
export interface LiveDataType {
  astrologers: RoomResponse[];
  liveSessions: RoomResponse[];
  astrologersChat: RoomResponse[];
}

export interface liveRoomType {
  roomIcon: string;
  userId: string;
  languages: string[];
  creatorSubCategories: string[];
  experience: string;
  id: number;
}

export interface userProfileType {
  name: string;
  country: string;
  photo: string;
  level: number;
  rating: string;
  rate: number;
  userId: string;
}

export interface sessionType {
  liveRoom: liveRoomType;
  userProfile: userProfileType;
}
export enum AssetTypes {
  BEANS = "beans",
  DIAMONDS = "diamonds",
  GIFTS = "gifts",
  GARI = "gari",
  POINTS = "points",
}

export enum PeriodTypes {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
  OVERALL = "OVERALL",
  CITY_CONTEST_DURATION = "CITY_CONTEST_DURATION",
  SPECIFIC_OCASSION_CONTEST = "SPECIFIC_OCASSION_CONTEST",
}

export enum THEMES {
  DARK = "dark",
  LIGHT = "light",
}

export enum USER_TYPE {
  USER = "USER",
  CREATOR = "CREATOR",
  CONSUMER = "CONSUMER",
}

export enum STREAM_TYPE {
  AUDIO = "AUDIO",
  LIVE = "LIVE",
}

export enum PAGE_TYPE {
  SCREEN = "screen",
  POPUP = "popup",
}

export interface TableShellData {
  title: string | number;
  subTitle: string | number;
}

export enum Days {
  Today = "Today",
  Yesterday = "Yesterday",
}

export enum EarningsDataKeys {
  totalBeans = "totalBeans",
  liveDuration = "liveDuration",
  callDuration = "callDuration",
}

export interface TableRowItem {
  data: Array<ShellDataType>;
  haveFloatingButton?: boolean;
  onButtonClick?: (a?: TableRowItem) => void;
  timeStamp?: number | Date;
  totalBeans?: number;
  liveDuration?: number;
  callDuration?: number;
}

export type ShellDataType = string | number | Date | TableShellData;

export type TableData = TableRowItem[];

export enum PAGE_LOAD_STATUS {
  SUCCESS = "Success",
  FAILURE = "Failure",
}

export enum FAILURE_REASON {
  SERVER = "Server Issue",
  NETWORK = "Network Issue",
  NA = "NA",
}

export enum ANONYMOUS_STATUS {
  ON = "On",
  OFF = "Off",
  NA = "NA",
}

export enum DIRECTION {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export interface ApiResponse<T> {
  data: T;
  statusCode: number;
}

interface ShellData {
  title: string | number;
  subTitle: string | number;
}
export interface RowItem {
  data: Array<string | number | ShellData | string | Date>;
  haveFloatingButton?: boolean;
  onButtonClick?: (a?: RowItem) => void;
}

export interface TokenReqParams {
  token?: string;
  contextId?: string;
}

export enum TRANSACTION {
  DEBIT = 1,
  CREDIT = 0,
}

export interface ProfileDataInterface {
  accountStatus: string;
  age: number;
  badgeStatus: number;
  countryCode: string;
  dob: string;
  birthDate: string;
  followers: number;
  following: number;
  gariPubKey: string;
  gender: number;
  isFeatured: boolean;
  isKYCDone: number;
  isProfileVerified: number;
  isUserVotedForBadge: boolean;
  location: string;
  name: string;
  postCount: number;
  preferredLanguage: string[];
  profilePic: string;
  status: string;
  tipEnabled: number;
  username: string;
  voteReceived: number;
  profileType: {
    name: USER_TYPE;
  };
  diamonds: number;
  beans: number;
  level: number;
  creatorLevel: number;
  earningsPerMinute: number;
  pricePerMinute: number;
  privateCallLevel: number;
  city: string;
  country: string;
  email: string;
  contactNumber: string;
}

export interface ProfileBEDataInterface {
  code: number;
  error: string;
  data: ProfileDataInterface;
  message: string;
}

export interface LiveRoomQueryProps {
  params: { roomType: string };
  searchParams: {
    token?: string;
    contextId?: string;
    roomId?: string;
    category?: string;
    subCategory?: string;
  };
}

export interface DiamondHistoryProps {
  currentDiamondBalance: number;
  data: TransactionItem[];
  token: string;
  hasMore: boolean;
  hasHistory?: boolean;
}

export type TransactionItem = {
  message: string;
  diamondValue: number;
  status: DIAMONDS_PURCHASE_STATUS;
  timeStamp: Date;
  details: {
    orderId: string;
    amountPaid: number;
  };
  type: number;
};

export enum DIAMONDS_PURCHASE_STATUS {
  PENDING = "0",
  SUCCESSFUL = "1",
  ALL = "3",
  FAILED = "4",
}

export const enum DIAMOND_TRANSACTION_STATUS {
  PENDING = 0,
  SUCCESSFUL = 1,
  ALL = 3,
  FAILED = 4,
}

export type TransactionsResponse = {
  data: TransactionItem[];
  total: number;
  hasMore: boolean;
  currentDiamondBalance?: number;
};

export enum Filters {
  Status = "Status",
  Timeline = "Timeline",
}

export type FilterValue = {
  [key: string]:
    | string
    | number
    | {
        startDate: number;
        endDate: number;
      };
};

export type FilterData = {
  [key in Filters]: FilterValue[];
};

export interface ServerTypeQuery {
  params: { slug: string };
  searchParams: { [key: string]: string };
}

export interface AstroCardData {
  title?: string;
  description?: string;
  moreLink?: string;
  image?: string;
}
export interface AstroPageData {
  title?: string;
  type?: string;
  data: AstroCardData[];
  formDetails?: {
    redirect: string;
    title: string;
    submitBtn: string;
    formFields?: {
      id: number;
      label: string;
      name: string;
      required: boolean;
    }[];
  };
}
export enum CurrencyType {
  BEANS = 0,
  DIAMONDS = 1,
  GIFTS = 2,
  VCARDS = 3,
  ASTROVCARDS = 6,
}
